import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

const Cancel = ({ location }) => {
  const [step, setStep] = useState(0)
  const [message, setMessage] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [reason, setReason] = useState('')
  const [name, setName] = useState('')
  const [store, setStore] = useState('')
  const [expired, setExpired] = useState('')
  const [loading, setLoading] = useState(false)
  const nextStep = () => {
    setStep(step + 1)
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    setMessage('')

    const {status} = await fetch(`/api/cancel-subscription`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({reason}),
    }).then(r => r.json());
    if(status === 'success') {
      nextStep()

    } else if(status === 'timeout') {
      setMessage('一定時間処理が行われなかったため、セッションタイムアウトされました。最初からやり直してください。')
    } else {
      setMessage('解約処理に失敗しました。再実行してください。')
    }
    setLoading(false)
  };
  const login = async (e) => {
    e.preventDefault()
    setLoading(true)
    setMessage('')

    const {status, name, store, expired, subscription, message } = await fetch(`/api/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
      }),
    }).then(r => r.json());
    console.log(name)
    if(status === true) {
      if(subscription) {
        setName(name)
        setStore(store)
        setExpired(expired)
        nextStep()
      } else {
        setMessage(message ?? 'お客様のアカウントは既に解約済みです。')
      }
      
    } else {
      setMessage('メールアドレスまたはパスワードが誤っています。再入力してください。')
    }
    setLoading(false)
  }
  

  switch (step) {
    case 1:
      return (
      <div className='form-outline form-small form-placeholder'>
      <div className='form-inline'>

        <div className='center w-100'>

          <h4 className='cancel_title'>解約</h4>
          <label className='field '>
            <div className="input">
              <input type="text"
                name="email" id=""
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            <span className='d-block m-l-1 placeholder'>ご登録メールアドレス<b className='icon-required'></b></span>
              <span className="line"></span>
            </div>
          </label>
          <label className='field  d-block'>
            <div className="input">
              <input type="password"
                name="password" id=""
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            <span className='d-block m-l-1 placeholder'>パスワード<b className='icon-required'></b></span>
              <span className="line"></span>
            </div>
          </label>
          <label className='field  d-block'>
            <div className="input">
              <textarea 
                name="reason" id=""
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            <span className='d-block m-l-1 placeholder'>解約理由をお聞かせください。</span>
              <span className="line"></span>
            </div>
          </label>
          <div className='error' dangerouslySetInnerHTML={{ __html: message }} />
          { loading ?
            <div className='w-100'><img src={require('./assets/images/loading.svg')} alt="Waiting..." width="50" style={{ margin: '20px auto', display: 'block' }} /></div>
            :
            <button className='btn mt-30' type='button' onClick={login}><span>次へ</span></button>
          }
        </div>
        
      </div>
    </div>
    )

    case 2:
      return (
        <form className='form-outline form-small form-cancel' onSubmit={handleSubmit}>
        <div className='form-inline'>
          <div className='center w-100'>
            <h4 className='cancel_title'>解約確認</h4>
            <div className='cancel-confirm'>
              <label className='d-block'>お名前</label>
              <p>{name}</p>
              <label className='d-block'>事業者名/サロン名</label>
              <p>{store}</p>
              <label className='d-block'>ご登録のメールアドレス</label>
              <p>{email}</p>
              <label className='d-block'>解約理由</label>
              <p>{reason}</p>
            </div>
            <p className='expired'><span className='cl-primary'>{expired}</span> までご利用いただけます。</p>
            <div className='error' dangerouslySetInnerHTML={{ __html: message }} />
            { loading ?
            <div className='w-100'><img src={require('./assets/images/loading.svg')} alt="Waiting" width="50" style={{ margin: '20px auto', display: 'block' }} /></div>
            :
            <button className='btn mt-30' type='button' onClick={handleSubmit}><span>解約</span></button>
          }
          </div>
        </div>
      </form>
      )
    case 3:
      return (
        <div className='form-outline form-small'>
          <div className='form-inline'>
            <div className='center w-100'>
          <h4 className='cancel_title'>解約完了</h4>
            <p className='note mnt'>お客様の解約手続きが完了いたしました。</p>
            <p className='note'>
これにより弊社のサービスは、<span className='text-underline'>{expired}</span>までご利用いただけます。<br/>
サービスの再開をご希望の方は、再度アカウント作成フォームよりお申し込みください。<br/>
（無料トライアルを体験された方は、次回以降は無料トライアル適用外となります。予めご了承ください。）<br/>
ご利用ありがとうございました。またのご利用をお待ちしております。</p>
            </div>
          </div>
      </div>
      )
    case 0:
    default:
      return (
        <div className='form-outline form-small'>
          <div className='form-inline'>
            <div className='center'>
              <h4 className='cancel_title'>解約のご確認事項</h4>
              <p className='note text-left'>
              １．解約後は、次回請求日からログインができなくなります。<br/>
２．解約後もデータは保存されるため、再登録後に引き続きデータをご利用いただけます。<br/>
３．無料トライアルを利用された方は、次回ご利用時に無料トライアルは適用されません。
              </p>
              <p className='note mnt'>解約しますか？</p>
              <button className='btn mt-30' type='button' onClick={nextStep}><span>解約ページへ進む</span></button>
            </div>
          </div>
        </div>
          )
  }
}


          export default withRouter(Cancel);

import React from 'react';
import { withRouter } from 'react-router-dom';

const Thankyou = () => {
  return (
    <div className='form-outline form-small'>
      <div className='form-inline'>
        <div className='center'>
        <h1>Thank You!</h1>
        <p className='note'>
        EstheMiruにご登録いただき、ありがとうございます。<br/>
      ご登録いただいたメールアドレス宛に登録メールを送信しました。<br/>
      登録メールが届かない場合は、運営会社(info@beauty-avatar.tech)までご連絡をお願いいたします。<br/>
        </p>
        <a  className='btn' href='https://esthemiru.tech/'><span>戻る</span></a>
        </div>
      </div>
      {/* <h1>Account</h1>

      <a href="/prices">Add a subscription</a>
      <a href="/">Restart demo</a>

      <h2>Subscriptions</h2>

      <div id="subscriptions">
        {subscriptions.map(s => {
          return <AccountSubscription key={s.id} subscription={s} />
        })}
      </div> */}
    </div>
  );
}

export default withRouter(Thankyou);

export const notification =  async (email, message)  => {
    await fetch(`/api/notification`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          message
        }),
      }).then(r => r.json());
}
import React from 'react';
import './App.scss';
import { BrowserRouter as Switch, Route } from 'react-router-dom';

import Cancel from './Cancel';
// import Prices from './Prices';
// import Register from './Register';
import Subscribe from './Subscribe';
import Information from './Information';
import Upgrade from './Upgrade';
import Thankyou from './Thankyou';
// import NotFound from './NotFound';
import Renew from './Renew';
function App(props) {
  return (
    <Switch>
      {/* <Route exact path="/">
        <Register />
      </Route> */}
      {/* <Route path="/">
        <Prices />
      </Route> */}
      <Route exact path="/">
        <Subscribe />
      </Route>
      <Route exact path="/upgrade">
        <Upgrade />
      </Route>
      <Route path="/re-register">
        <Renew />
      </Route>
      <Route path="/information">
        <Information />
      </Route>
      <Route path="/thank-you">
        <Thankyou />
      </Route>
      <Route path="/cancel">
        <Cancel />
      </Route>
      {/* <Route path="/*">
        <NotFound />
      </Route> */}
    </Switch>
  );
}

export default App;

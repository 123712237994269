import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { termsContent } from './assets/content/terms.js';
import { advertContent } from './assets/content/advert.js';
import { notification } from './assets/methods/notification.js';

import {
    CardElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import { Redirect } from 'react-router-dom';
const Upgrade = ({ location }) => {
    // Get the lookup key for the price from the previous page redirect.
    const [message, setMessage] = useState('');
    const [intent, setIntent] = useState();
    const [name, setName] = useState('')
    // const [clientSecret, setClientSecret] = useState('')
    const [company, setCompany] = useState('')
    const [address, setAddress] = useState('')
    const [store, setStore] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [priceId, setPriceId] = useState('')
    const [prices, setPrices] = useState([]);
    const [terms, setTerms] = useState(false);
    const [popTitle, setPopTitle] = useState('terms');

    const [advert, setAdvert] = useState(false);
    const [popup, setPopup] = useState(false);
    const [exist, setExist] = useState(false);
    const [agency, setAgency] = useState('');
    const [nocancel, setNocancel] = useState(false);

    const options = {
        hidePostalCode: true,
    }
    useEffect(() => {
        const fetchPrices = async () => {
            const snocancel = new URLSearchParams(location.search).get('nocancel') != null ? true : false
            setNocancel(snocancel)
            const splan = new URLSearchParams(location.search).get('plan') ?? 'plan01'
            const smode = new URLSearchParams(location.search).get('mode') ?? 'basic'
            const ref_agency = new URLSearchParams(location.search).get('ref_agency') ?? ''

            const { prices } = await fetch(`/api/config?plan=${splan}&mode=${smode}&ref_agency=${ref_agency}`).then(r => r.json());
            setAgency(ref_agency)
            setPrices(prices);
            setPriceId(prices.filter(p => p.active)[0]?.id)

        };
        fetchPrices();

    }, [])
    // Initialize an instance of stripe.
    const stripe = useStripe();
    const elements = useElements();

    if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return '';
    }
    const upgrade = async (priceId, paymentId) => {
        const { subscriptionId, clientSecret, error } = await fetch(`/api/upgrade`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                paymentId,
                priceId,
                renew: true
            }),
        }).then(r => r.json());
        return { subscriptionId, clientSecret, errorSub: error }
        // setSubscriptionData({ subscriptionId, clientSecret });
    }
    const agreeTerms = () => {
        setTerms(true)
        setPopup(false)
    }
    const agreeAdvert = () => {
        setAdvert(true)
        setPopup(false)
      }
    const login = async () => {
        setLoading(true)
        if (! /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
            setMessage('メールアドレスのフォーマットが正しくありません。<br>確認の上、正しいメールアドレスを入力してください。')
            setLoading(false)
            return;
        }
        const { status, user } = await fetch(`/api/login-upgrade`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email,
                password,
            }),
        }).then(r => r.json());
        if (status) {
            // if (user.subscription_id !== '' && new Date(user.expired_time).getTime() > new Date().getTime()) {
            //     setMessage('このメールアドレスは既に登録されています。')
            // } else {
                setAddress(user.billing_address)
                setStore(user.store_address)
                setName(user.name)
                setCompany(user.company)
                setPhone(user.phone)
                setMessage('')

                setExist(true)
            // }

        } else {
            setMessage('このアカウントは存在しません。メールアドレスを確認ください。')
        }
        setLoading(false)

    }
    const updateCustomer = async (paymentId) => {
        const { customer, error } = await fetch(`/api/update-customer`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                agency,
                paymentId,
                email,
                name,
                nocancel,
                company,
                address,
                store,
                phone,
                renew: true
            }),
        }).then(r => r.json());

        return { customer, error };
    }
    // When the subscribe-form is submitted we do a few things:
    //
    //   1. Tokenize the payment method
    //   2. Create the subscription
    //   3. Handle any next actions like 3D Secure that are required for SCA.
    const handleSubmit = async (e) => {
        e.preventDefault();

        setMessage('')

        if (!terms || !advert) return;

        setLoading(true)
        if (priceId === '') {
            setMessage('プランを選択してください。')
            setLoading(false)
            return;
        }
        if (email === '' || name === '' || company === '' || phone === '' || address === '') {

            setMessage('入力項目を確認ください。<br>*のマークがついている項目は入力が必須です。')
            setLoading(false)
            return;
        }
        if (! /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
            setMessage('メールアドレスのフォーマットが正しくありません。<br>確認の上、正しいメールアドレスを入力してください。')
            setLoading(false)
            return;
        }
        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        const pm = await stripe
            .createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details: {
                    name,
                    address: {
                        // country: 'jp',
                        line1: address
                    }
                },
            })
        if (pm?.error) {
            setMessage(pm.error.message.indexOf('unknow') >= 0 ? 'このカードは本システムではご利用できません。別のカードをお試しください。' : pm.error.message)
            notification(email, pm.error.message)
            setLoading(false)

            return;
        }

        const { customer, error } = await updateCustomer(pm.paymentMethod.id)

        if (error !== '') {
            setMessage(error);
            notification(email, error)
            
            setLoading(false)
            return;
        }

        const { clientSecret, errorSub } = await upgrade(priceId, pm.paymentMethod.id)

        if (errorSub !== '') {
            // setMessage(errorSub)
            notification(email, errorSub)

            setMessage('請求先情報が正しくありません。<br>確認の上、正しい請求先情報を入力してください。');
            setLoading(false)
            return;
        }
        // const cardElement = elements.getElement(CardElement);

        // Use card Element to tokenize payment details
        // const {setupIntent} =await stripe.confirmCardSetup(clientSecret, {
        //   payment_method: {
        //     card: cardElement,
        //     billing_details: {
        //       name,
        //     },
        //   },
        // })
        const { paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: cardElement,
            }
        });
        setIntent(paymentIntent);
        // console.log(setupIntent)
        if (error) {
            // show error and collect new card details.
            // setMessage(error.message);
            setMessage('請求先情報が正しくありません。<br>確認の上、正しい請求先情報を入力してください。');
            setLoading(false)
            return;
        }
        setIntent(paymentIntent);

        if (!paymentIntent || paymentIntent.status !== 'succeeded') {
            notification(email, paymentIntent.error.message)

            setMessage('請求先情報が正しくありません。<br>確認の上、正しい請求先情報を入力してください。');
        }
        setLoading(false)
    }

    if (intent && intent.status === 'succeeded') {
        return <Redirect to={{ pathname: '/thank-you' }} />
    }
    // if(priceId === '' ) {
    //   return <Redirect to={{pathname: '/'}} />
    // }

    return (
        <><h1>EstheMiru (エステミル) アカウント登録</h1>
            {!exist ?
                <div className='form-outline form-small form-cancel form-placeholder'>
                    <div className='form-inline'>
                        <div className='center w-100'>
                            <label className='field '>

                                <div className="input">
                                    <input type="text"
                                        name="email" id="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}

                                    />
                                    <span className='d-block m-l-1 placeholder'>Email<b className='icon-required'></b></span>
                                    <span className="line"></span>
                                </div>
                            </label>
                            <label className='field  d-block'>
                                <div className="input">
                                    <input type="password"
                                        name="password" id=""
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <span className='d-block m-l-1 placeholder'>パスワード<b className='icon-required'></b></span>
                                    <span className="line"></span>
                                </div>
                            </label>
                            <div className='error' dangerouslySetInnerHTML={{ __html: message }} />

                            {loading ?
                                <div className='w-100'><img src={require('./assets/images/loading.svg')} alt="Waiting..." width="50" style={{ margin: '20px auto', display: 'block' }} /></div>
                                :
                                <button className='btn' type='button' onClick={login}>
                                    <span>次へ</span>
                                </button>
                            }
                        </div>
                    </div>
                </div>
                :
                <div className='form-outline '>
                    <div className='form-inline'>
                        <div className='plans'>

                            <div className="prices">
                                {prices.map((price) => {
                                    return (
                                        <div key={price.id} className={(price.id === priceId && price.active ? ' active' : (price.active ? ' ' : 'disabled ')) + ' price-item '}>
                                            <div className='price-content'>
                                                <h4>{price.metadata.name}</h4>
                                                <p>
                                                    {price.currency === "jpy" ? '¥' : price.currency}{price.unit_amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}/{price.recurring.interval == 'month' ? '月' : price.recurring.interval}
                                                </p>
                                                <p className='height50'></p>

                                            </div>
                                            <button onClick={() => { if (price.active) setPriceId(price.id) }} className='btn'>
                                                <span>選択</span>
                                            </button>
                                        </div>
                                    )
                                })}
                            </div>
                            {agency != '' ?
                                <div className='agency'>
                                    Agent_id
                                    <span>{agency}</span>
                                </div>
                                : <></>
                            }
                            <div className='terms'>
                                <a className='Link Link--primary' href="https://stripe.com/legal/end-users" target="_blank" rel="noopener noreferrer"><span>利用規約</span></a>
                                <span>　/　</span>
                                <a className="Link Link--primary" href="https://stripe.com/privacy" target="_blank" rel="noopener noreferrer"><span>プライバシポリシー</span></a>
                            </div>

                        </div>
                        <form onSubmit={handleSubmit} className="form">
                            <label className='field '>
                                <span className='d-block m-l-1'>名前<b className='icon-required'></b></span>
                                <div className="input">
                                    <input type="text"
                                        name="name" id="" placeholder="名前"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}

                                    />
                                    <span className="line"></span>
                                </div>
                            </label>
                            <label className='field '>
                                <span className='d-block m-l-1'>事業者名/サロン名<b className='icon-required'></b></span>
                                <div className="input">
                                    <input type="text"
                                        name="company" id="" placeholder="事業者名/サロン名"
                                        value={company}
                                        onChange={(e) => setCompany(e.target.value)}

                                    />
                                    <span className="line"></span>
                                </div>
                            </label>
                            <label className='field'>
                                <span className='d-block m-l-1'>電話番号<b className='icon-required'></b></span>
                                <div className="input">
                                    <input type="text"
                                        name="phone" id="" placeholder="電話番号"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}

                                    />
                                    <span className="line"></span>
                                </div>
                            </label>
                            <label className='field'>
                                <span className='d-block m-l-1'>請求先住所<b className='icon-required'></b></span>
                                <div className="input">
                                    <input type="text"
                                        name="address" id="" placeholder="請求先住所"
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}

                                    />
                                    <span className="line"></span>
                                </div>
                            </label>
                            <label className='field'>
                                <span className='d-block m-l-1'>店舗住所</span>
                                <div className="input">
                                    <input type="text"
                                        name="store" id="" placeholder="店舗住所"
                                        value={store}
                                        onChange={(e) => setStore(e.target.value)}

                                    />
                                    <span className="line"></span>
                                </div>
                            </label>
                            <label className='field'>
                                <span className='d-block m-l-1'>Email<b className='icon-required'></b></span>
                                <div className="input">
                                    <input type="text"
                                        name="email" id="" placeholder="Email"
                                        value={email}
                                        readOnly={true}

                                    />
                                    <span className="line"></span>
                                </div>
                            </label>
                            <hr />
                            {!exist ?
                                <></> :
                                <CardElement options={options} />
                            }
                            <div className='error' dangerouslySetInnerHTML={{ __html: message }} />
                            <label className='checkbox'>
                                <input type="checkbox" onChange={() => setAdvert(!advert)} checked={advert} />
                                <span><span className='link' onClick={(e) => { setPopTitle('advert'); setPopup(true)}} >広告使用する際の注意</span>に同意する</span>
                            </label>
                            <label className='checkbox'>
                                <input type="checkbox" onChange={() => setTerms(!terms)} checked={terms} />
                                <span>エステミルの<span className='link' onClick={(e) => {setPopTitle('terms');setPopup(true)}} >利用規約</span>に同意する</span>
                            </label>
                            {loading ?
                                <div className='w-100'><img src={require('./assets/images/loading.svg')} alt="Waiting..." width="50" style={{ margin: '20px auto', display: 'block' }} /></div>
                                :
                                <button className='btn' disabled={!terms || !advert}>
                                    <span>登録</span>
                                </button>
                            }


                            <p className='privacy'>サブスクリプションを確認すると、今回の支払い及び今後の支払いについて エステミル課金 が規約に従ってカードに請求できるようになります。サブスクリプションはいつでもキャンセルすることができます。</p>

                        </form>
                    </div>
                </div>
            }
            {popup ?
                <>
                    <div className='pop_bg' />
                    <div className='pop'>

                        <div className='pop_header'>
                        <h2 className='title'>{ popTitle == 'terms' ? 'エステミル利用規約' : 'エステミルを広告に使用する際の注意点'}</h2>
                        <button className='pop_close' type='button' onClick={(e) => setPopup(false)}><span></span></button>
                        </div>
                        <div className='pop_body'>
                        <p dangerouslySetInnerHTML={{ __html: popTitle == 'terms' ? termsContent.replace(/\r?\n/g, '<br>') : advertContent.replace(/\r?\n/g, '<br>') }} />
                        </div>
                        <div className='pop_footer'>
                        <button type='button' onClick={popTitle == 'terms' ? agreeTerms : agreeAdvert} className='btn'><span>同意する</span></button>
                        </div>
                    </div>
                </>
                : <></>
            }


        </>
    )
}

export default withRouter(Upgrade);

export const advertContent = `エステミルを利用して得られた画像やデータをサロンの広告宣伝に使っていただくために、以下の点を遵守してください。
1. 下記のクレジットを画像の下部に入れること
 　©EstheMiru

2. 画像や数値を修正、加工しないこと
エステサロンの広告宣伝において、ビフォーアフター写真は施術の効果を視覚的に伝える非常に有効な手段ですが、画像を加工したり、数値を偽って掲載したりすることは法律で禁止されています。また、メイクにより効果を強調することも禁止します。
エステミルで得られた比較の写真を掲載する場合、事実のみを掲載し、注意書きとして以下の点を含んだ表記をするようにしてください。
(1) 施術内容
(2) 施術期間（時間）、回数
(3) 具体的な数値

3. 景品表示法上の「不当表示」に該当しないこと
他のサービスと比べて著しく内容や価格が優れている又は実際よりも優れた効果が得られるといった表現をしてはなりません（例えば「業界最安値」「最強のマシンを使った施術」など）。エステミルを利用した客観的なデータのみを記載するに留めてください。

4. 個人差があることを記載すること
施術の効果の現れ方は個人の体質や生活習慣等によって異なるため、全員がビフォーアフター写真と同様の効果を得られるわけではありません。従って、「全員に効果があるわけではなく、効果には個人差がある」旨の注意喚起を記載してください。

5. 医療行為ではないことを明記すること
医療行為と誤認される表現は禁止します。ビフォーアフターの写真と共に「頭蓋骨が矯正できる」「リフトアップ」「たるみが消える」「治す」「〇〇療法」「治療」などの文言を記載してはなりません。

6. お客様本人の書面による同意を得ること
お客様の顔の画像は、個人情報です。SNS等を使って広告宣伝を行う場合、必ず書面（次ページに見本を添付します。）により掲載の同意を取得してください。`
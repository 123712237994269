export const termsContent = `第 1 条（目的）
1. この利用規約（以下「本規約」といいます。）は、株式会社Alice（以下「当社」といいます。）が運営する「エステミル」（以下「本サービス」といいます。）を利用する契約者に適用されます。契約者は、本規約に同意の上、本サービスを利用します。
2. 本規約は、本サービスの利用条件を定めています。本サービスの契約者は全て本規約に従い、利用環境等の条件に応じて、本規約の定める条件に従って本サービスを利用します。
3. 契約者が本規約に同意することにより、当社との間に本契約（第2条で定義します。）が成立します。
4. 契約者は、自らの責任で本サービスの利用者等（第7条2項で定義します。）に本規約を遵守させるものとします。
第 2 条（定義）
本規約において使用する以下の用語は、以下の各号に定める意味を有します。
(1) 本契約
本規約を契約条件として当社及び契約者との間で締結される、本サービスの利用契約
(2) 本サービス
当社が提供するアプリ「エステミル」および関連するサービスの総称
(3) 本コンテンツ
本サービス上で実行または提供される文字、音、静止画、動画、グラフ、ソフトウエア、入力情報、その他データ等の総称
(4) 契約者
当社との間で本サービスの利用契約を締結する事業者（法人、個人事業主、個人等の法人格を問いません。）
(5) 利用店舗
契約者が所有又は運営し、本サービスの利用対象として利用登録をした店舗
(6) 顧客
利用店舗の顧客であり、契約者が本サービスを利用してデータ分析を行う対象となる全ての個人
(7) 登録情報
契約者が本サービスに登録した契約者及び利用店舗に関する情報で、ID及びパスワードを含む
(8) 顧客情報
本サービスにより取得した顧客に関する一切の情報で、個人情報、施術の記録、顔写真及び分析データを含むが、それらに限らない
(9) 分析データ
本サービスを利用して測定した顧客の顔に関するデータ及び分析結果
(10) ID
本サービスの利用のために契約者が固有に持つ文字列
(11) パスワード
ＩＤに対応して利用店舗が固有に設定する暗号
(12) 個人情報
氏名、メールアドレス、顔写真画像、その他電磁的記録（電子的方式、磁気的方式その他人の知覚によって認識することができない方式により作られた記録）等に記載された、特定の個人を識別できる単独の情報、または他の情報と容易に照合でき、それにより特定の個人を識別できる情報の総称
(13) 知的財産権
著作権（著作権法第27条及び第28条の権利を含む。）、特許権、実用新案権、意匠権、商標権等の知的財産権を受ける権利及び当該権利に基づき取得される知的財産権
(14) 通信機器
スマートフォン、タブレット端末及びコンピューター機器の総称
第 3 条（利用登録）
1. 本サービスの利用を希望する事業者は、本規約の内容に同意の上、当社が定める手続きにより利用申込書の提出及び当社所定の利用登録を行います。
2. 契約者は、登録情報に変更が発生した場合、直ちに、登録情報の変更手続を行う義務を負います。
3. 当社は、当社の裁量により、利用申込を拒否する場合があります。
4. 契約者は、本サービス上のアカウントを第三者に対して利用、貸与、譲渡、売買又は質入等をすることはできません。
第 4 条（本サービスの内容）
本サービスは、契約者が顧客に対して行った小顔施術につき、AI技術を用いて小顔施術効果の診断を行うため、施術前後の顧客の顔を数値化して分析を行うソフトウェアです。契約者は、本サービスのかかる特性を踏まえ、自己の判断と責任において本サービスを利用するものとします。
第 5 条（導入支援等）
当社は、契約者における本サービスの導入に当たり必要な支援（契約者の業務改善に関するヒアリングその他必要な支援）を行うよう努めるものとし、契約者は、本サービスの業務利用を通じて、本サービスに対する不満、改善要望、その他利用者及び顧客の要望を集約し、当社に対してフィードバックするよう努めるものとします。
第 6 条（利用料金）
1. 本サービスの初期費用及び月額利用料金（以下総称して「利用料金等」といいます。）は、本サービスを掲載するウェブサイト又は利用申込書に定める料金表に従います。
2. 契約者は、当社が定める方法でのみ、本サービスに係る利用料金等の決済を行うことができます。
3. 契約者が、本サービスの利用料金等を所定の支払期日が過ぎてもなお支払わない場合、契約者は、所定の支払期日の翌日から支払い日の前日までの日数に、年14.6％の利率で計算した金額を遅延損害金として支払うものとします。
4. 当社は、契約者の同意なく、当社の裁量において本サービスの利用料金を変更することがあります。当社は利用料金を変更する前に契約者へ変更を通知します。
第 7 条（利用制限）
1. 本サービスは、契約者の業務における利用を目的として提供されるものであり、 本サービスが契約者に対して提供するコンテンツを、顧客本人を除く第三者に販売することその他商業目的で使用することはできません。
2. 契約者は、本サービスを、契約者の役員又は従業員、契約社員、アルバイト等、契約者の店舗で働く者（以下「利用者等」といいます。）に対してのみ使用させることができるものとし、その他の第三者に対して使用させることはできません。
3. 契約者は、利用者等に対し、本規約に定める条件を周知し、これに従わせるものとします。
第 8 条（顧客ID）
1. 契約者は、当社が定める方法及び使用条件に基づいて、顧客に対してID（以下「顧客ID」といいます。）を付与するものとします。その際、契約者は、契約者の責任において、顧客の個人情報提供に対する同意を顧客本人より取得するものとします。
2. 契約者は、自らの管理責任により、顧客IDを不正使用されないよう管理するものとします。
3. 契約者は、いかなる場合も、顧客IDを第三者に開示、貸与することはできません。
4. 当社は、顧客IDの不正利用によって契約者又は顧客に生じた損害について責任を負いません。当社は、顧客IDの認証を行った後に行われた本サービスの利用行為については、すべて契約者に帰属するものとみなすことができます。
第 9 条（情報及び機器に関する管理）
1. 契約者は、本サービスの提供を受けるために必要なカメラ付きタブレット端末等当社が指定する機器及び通信手段等の環境を全て自らの費用と責任で備えます。また、本サービスの利用にあたり必要となる通信費用は、全て契約者の負担とします。
2. 契約者は、登録情報、顧客情報及び通信機器の管理責任を負います。登録情報、顧客情報及び通信機器の管理不十分、使用上の過誤、第三者の使用等による損害の責任は契約者が負い、当社は当社に故意又は過失のない限り一切の責任を負いません。
3. 契約者は、登録情報、顧客情報又は通信機器を第三者に使用されるおそれのある場合は、直ちに当社にその旨を連絡するとともに、当社の指示がある場合はこれに従います。
第 10 条（本サービスの変更・停止等）
1. 当社は、契約者に事前に通知することなく、本サービスの内容の全部又は一部を変更又は追加することができるものとします。但し、当該変更又は追加によって、変更又は追加前の本サービスのすべての機能・性能が維持されることを保証するものではありません。
2. 当社は、以下のいずれかに該当する場合には、本サービスの利用の全部又は一部を停止又は中断することができるものとします。この場合において、当社は契約者に対して、できる限り事前に通知するよう努めるものとします。
(1) 本サービスに係るコンピューター・システムの点検又は保守作業を定期的又は緊急に行う場合
(2) ンピューター、通信回線等が事故により停止した場合
(3) 火災、停電、天災地変、法令・規則の制定・改廃、疫病・感染症の流等の不可抗力により本サービスの運営ができなくなった場合
(4) その他、当社が本サービスの停止又は中断を必要と合理的に判断した場合
3. 当社は、本条により契約者に生じた不利益、損害について責任を負いません。
第 11 条（知的財産権等）
1. 契約者は、方法又は形態の如何を問わず、本サービスにおいて提供される全ての情報及びコンテンツ（以下総称して「当社コンテンツ」といいます。）を著作権法に定める、私的使用の範囲を超えて複製、転載、公衆送信、改変その他の利用をすることはできません。但し、当社は、契約者に対し、契約者が顧客本人に対してのみ本サービスによる分析結果を提供（複製を含む。）することを許諾します。
2. 当社コンテンツに関する知的財産権は、当社又は当社がライセンスを受けているライセンサーに帰属し、契約者には帰属しません。また、契約者は、知的財産権の存否にかかわらず、当社コンテンツについて、前項但し書きを除き、複製、配布、転載、転送、公衆送信、改変、翻案その他の二次利用等を行ってはなりません。
3. 契約者が本条の規定に違反して問題が発生した場合、契約者は、自己の費用と責任において当該問題を解決するとともに、当社に何らの不利益、負担又は損害を与えないよう適切な措置を講じなければなりません。
第 12 条（個人情報の取り扱い）
1. 当社は、個人情報について、当社が別途定めるプライバシーポリシー（以下「プライバシーポリシー」といいます。）に則り、適正に取り扱うこととします。
2. 契約者及び当社は、本サービスに入力された顧客情報を本サービス提供以外の目的で利用しないものとし、個人情報の保護に関する法律及びプライバシーポリシーに基づいて、紛失・破壊・改竄・漏洩等の危険から保護するための合理的な安全管理措置を講じ、厳重に管理するものとします。
3. 当社は、本サービスの提供のため必要がなくなった顧客情報に関して、一切のコピーを残すことなく、当社責任の下で速やかに破棄するものとします。
第 13 条（禁止事項）
1. 当社は、契約者による本サービスの利用に際して、以下の各号に定める行為を禁止します。
(1) 本規約に違反する行為
(2) 当社、他の契約者、利用者等、顧客又は第三者の知的財産権、特許権、実用新案権、意匠権、商標権、著作権、肖像権等の財産的又は人格的な権利を侵害する行為又はこれらを侵害する恐れのある行為
(3) 当社、他の契約者、顧客又は第三者に不利益若しくは損害を与える行為又はその恐れのある行為
(4) 不当に他人の名誉や権利、信用を傷つける行為又はその恐れのある行為
(5) 法令又は条例等に違反する行為
(6) 犯罪行為、犯罪行為に結びつく行為若しくはこれを助長する行為又はその恐れのある行為
(7) 当社のシステムへの不正アクセス、それに伴うプログラムコードの改ざん、位置情報の改ざん、故意に虚偽、通信機器の仕様その他アプリケーションを利用してのチート行為、コンピューターウィルスの頒布その他本サービスの正常な運営を妨げる行為又はその恐れのある行為
(8) マクロ及び操作を自動化する機能やツール等を使用する行為
(9) 本サービスの信用を損なう行為又はその恐れのある行為
(10) 他の契約者のアカウントの使用その他の方法により、第三者になりすまして本サービスを利用する行為
(11) その他当社が不適当と判断する行為
第 14 条（解除）
1. 当社は、契約者が、以下の各号のいずれかの事由に該当する場合は、事前に通知又は催告することなく、当該契約者について本サービスの利用を一時的に停止し、又は本サービス利用契約を解除することができます。
(1) 本規約のいずれかの条項に違反した場合
(2) 債務の全部又は一部の履行が不能である場合又は契約者がその債務の全部又は一部の履行を拒絶する意思を明確に表示した場合
(3) 本サービスの利用にあたり当社に提供した情報又は当社が契約者に要請した情報に虚偽の事実があることが判明した場合
(4) 当社、他の契約者その他の第三者に損害を生じさせるおそれのある目的又は方法で本サービスを利用した、又は利用しようとした場合
(5) 手段の如何を問わず、本サービスの運営を妨害した場合
(6) 支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始若しくはこれらに類する手続の開始の申立てがあった場合
(7) 自ら振出し、若しくは引受けた手形若しくは小切手につき、不渡りの処分を受けた場合、又は手形交換所の取引停止処分その他これに類する措置を受けた場合
(8) 差押、仮差押、仮処分、強制執行又は競売の申立てがあった場合
(9) 当社からの連絡に対して応答がない場合
(10) その他当社が不適当と判断した場合。
2. 当社は、本条に基づき当社が行った行為により契約者に生じた損害について一切の責任を負いません。
第 15 条（非保証・免責）
1. 本サービスの内容について、その完全性、正確性及び有効性等について、当社は一切の保証をしません。また、当社は、本サービスに中断、中止その他の障害が生じないことを保証しません。
2. 契約者が本サービスを利用するにあたり、本サービスから本サービスに関わる第三者が運営する他のサービス（以下「外部サービス」といいます。）に遷移する場合があります。その場合、契約者は、自らの責任と負担で外部サービスの利用規約等に同意の上、本サービス及び外部サービスを利用します。なお、外部サービスの内容について、その完全性、正確性及び有効性等について、当社は一切の保証をしません。
3. 契約者が登録情報の変更を行わなかったことにより損害を被った場合でも、当社は一切の責任を負いません。
4. 契約者は、法令の範囲内で本サービスをご利用ください。本サービスの利用に関連して契約者が日本又は外国の法令に触れた場合でも、当社は一切の責任を負いません。
5. 予期しない不正アクセス等の行為によって登録情報又は顧客情報を盗取された場合でも、それによって生じる契約者又は顧客の損害等に対して、当社は一切の責任を負いません。
6. 当社は、天災、地変、火災、ストライキ、通商停止、戦争、内乱、感染症の流行その他の不可抗力により本契約の全部又は一部に不履行が発生した場合、一切の責任を負いません。
7. 本サービスの利用に関し、契約者が他の契約者又は顧客との間でトラブル（本サービス内外を問いません。）になった場合でも、当社は一切の責任を負わず、契約者が自らの費用と負担において解決します。
第 16 条（損害賠償責任）
1. 契約者は、本規約の違反又は本サービスの利用に関連して当社に損害を与えた場合、当社に発生した損害（逸失利益及び弁護士費用を含みます。）を賠償します。
2. 契約者が、本サービスに関連して顧客又は第三者からクレームを受け又はそれらの者との間で紛争を生じた場合には、直ちにその内容を当社に通知するとともに、契約者の費用と責任において当該クレーム又は紛争を処理し、当社からの要請に基づき、その経過及び結果を当社に報告するものとします。
3. 当社は、当社の故意又は重過失により本契約に違反して契約者に損害を与えた場合、契約者に直接かつ現実に生じた通常の損害を賠償します。なお、当社が損害を賠償する場合は、損害発生日から直近1年間の月額利用料金の累積総額を上限とします。
第 17 条（本サービスの廃止）
1. 当社は、当社が本サービスの提供を廃止すべきと合理的に判断した場合、契約者に対して事前に通知の上、本サービスの提供を廃止できます。
2. 前項の場合、当社に故意又は重過失がある場合を除き、当社は一切の責任を負いません。
第 18 条（秘密保持）
1. 契約者及び当社は、本サービスの提供に関して知り得た相手方の秘密情報（本サービスに関するノウハウ、当社のシステムに関する情報、技術上又は営業上の一切の秘密情報を含みます。）を、厳重かつ適正に管理するものとし、相手方の事前の書面による同意なく第三者（当社の委託先を含みます。）に開示、提供及び漏洩しないものとします。
2. 次の各号の情報は、秘密情報に該当しないものとします。
(1) 開示を受けた時、既に所有していた情報
(2) 開示を受けた時、既に公知であった情報又はその後自己の責に帰さない事由により公知となった情報
(3) 開示を受けた後に、第三者から合法的に取得した情報
(4) 開示された秘密情報によらず独自に開発し又は創作した情報
(5) 法令の定め又は裁判所の命令に基づき開示を要請された情報
3. 契約者及び当社は、相手方の指示があった場合又は本契約が終了した場合は、相手方の指示に従い速やかに秘密情報を、原状に回復した上で返却又は廃棄し、以後使用しないものとします。
4. 当社は、契約者の同意を得て当社の関連会社又は委託先に契約者の秘密情報を開示した場合、当該関連会社及び委託先の当該秘密情報の取扱いについて一切の責任を負いません。
5. 当社は、本サービスを提供する目的のために、契約者の秘密情報を利用することができます。
第 19 条（反社会的勢力の排除）
1. 契約者及び当社は、現在、暴力団、暴力団員、暴力団員でなくなった時から5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロ又は特殊知能暴力集団等、その他これらに準ずる者（以下総称して「暴力団員等」といいます。）に該当しないこと、及び次の各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを保証します。
(1) 暴力団員等が経営を支配していると認められる関係を有すること
(2) 暴力団員等が経営に実質的に関与していると認められる関係を有すること
(3) 自己、自社若しくは第三者の不正の利益を図る目的又は第三者に損害を加える目的をもってする等、不当に暴力団員等を利用していると認められる関係を有すること
(4) 暴力団員等に対して資金等を提供し、又は便宜を供与する等の関与をしていると認められる関係を有すること
(5) 役員又は経営に実質的に関与している者が暴力団員等と社会的に非難されるべき関係を有すること
2. 契約者及び当社は、自ら又は第三者を利用して次の各号のいずれにも該当する行為を行わないことを確約します。
(1) 暴力的な要求行為
(2) 法的な責任を超えた不当な要求行為
(3) 取引に関して、脅迫的な言動をし、又は暴力を用いる行為
(4) 風説を流布し、偽計を用い又は威力を用いて相手方の信用を毀損し、又は相手方の業務を妨害する行為
(5) その他前各号に準ずる行為
3. 契約者及び当社は、相手方が、暴力団員等若しくは第1項各号のいずれかに該当し、若しくは前項各号のいずれかに該当する行為をし、又は第1項の規定にもとづく表明・保証に関して虚偽の申告をしたことが判明した場合には、自己の責に帰すべき事由の有無を問わず、相手方に対して何らの催告をすることなく本契約を解除することができます。
4. 契約者及び当社は、前項により本契約を解除した場合、相手方に損害が生じたとしてもこれを一切賠償する責任はないことを確認し、これを了承します。
第 20 条（お問い合わせ対応）
1. 当社は、本サービスに関する契約者からのお問い合わせに対して回答するよう努めますが、法令又は本規約上、当社に義務又は責任が発生する場合を除き、回答の義務を負いません。
2. 当社は、契約者からのお問い合わせに回答するか否かの基準を開示する義務を負いません。
第 21 条（地位の譲渡等）
契約者及び当社は、相手方の書面による事前の承諾なく、本契約上の地位又は本規約に基づく権利若しくは義務の全部又は一部につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。但し、株式譲渡若しくは事業譲渡又は合併、会社分割その他の組織再編についてはこの限りではありません。
第 22 条（分離可能性）
1. 本規約の規定の一部が法令に基づいて無効と判断されても、本規約の他の規定は有効とします。
2. 本規約の規定の一部がある契約者との関係で無効又は取消となった場合でも、本規約は他の契約者との関係では有効とします。
第 23 条（再委託）
当社は、本サービスの提供に関して、全部又は一部の業務を、当社の責任において第三者に再委託できるものとします。
第 24 条（本契約の有効期間）
1. 本契約の有効期間は、本契約成立時から契約者が退会するまでの間とします。
2. 一旦退会された契約者が再度本サービスをご利用される際は、再度当社に対し利用申込書を提出したうえで、初期費用をお支払いいただく場合があります。[A1]
3. 第10条第3項、第11条から第13条、第15条、第16条、第17条、第19条4項、本条、第26条及び第27条の規定は、本契約の終了後も有効に存続するものとします。
第 25 条（本規約の変更）
1. 当社は、以下の各号のいずれかに該当する場合は、民法第548条の4の規定に基づき本規約を随時変更できます。本規約が変更された後の本契約は、変更後の本規約が適用されます。
(1) 本規約の変更が、契約者の一般の利益に適合するとき
(2) 本規約の変更が、契約をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性及びその内容その他の変更に係る事情に照らして合理的なものであるとき
2. 当社は、本規約の変更を行う場合は、変更後の本規約の効力発生時期を定め、効力発生時期の2週間前までに、変更後の本規約の内容及び効力発生時期を契約者に通知、本サービス上への表示その他当社所定の方法により契約者に周知します。
3. 前二項の規定にかかわらず、前項の本規約の変更の周知後に契約者が本サービスを利用した場合又は当社所定の期間内に契約者が解約の手続をとらなかった場合、当該契約者は本規約の変更に同意したものとします。
第 26 条（準拠法）
本規約に関する準拠法は、全て日本国の法令が適用されます。
第 27 条（合意管轄）
契約者と当社との間における一切の訴訟は、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
第 28 条（その他）
1. 契約者は、本規約に定めのない事項について、当社がホームページ等で細目等を別途定めた場合、これに従います。この場合、当該細目等は、本規約と一体をなします。
2. 細目等は、当社所定の箇所に掲載した時点より効力を生じます。
3. 細目等と本規約の内容に矛盾抵触がある場合、本規約が優先します。
附則
2022年12月10日：制定・施行
`